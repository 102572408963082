<template>
  <div style="height: 800px; overflow-y:scroll; overflow-x: hidden; ">
    <a-form :form="form" :layout="formLayout" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="账册编号">
            <a-input
              v-decorator="['ems_no', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="进出口标记">
            <a-input
              v-decorator="['ie_flag', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="申报海关代码">
            <a-input
              v-decorator="['customs_code', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="口岸海关代码">
            <a-input
              v-decorator="['port_code', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订购⼈">
            <a-input
              v-decorator="['buyer_name', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="订购⼈身份证">
            <a-input
              v-decorator="['buyer_id_number', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订购⼈电话">
            <a-input
              v-decorator="['buyer_telephone', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="收货地址">
            <a-input
              v-decorator="['consignee_address', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="申报企业名称">
            <a-input
              v-decorator="['agent_name', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="申报企业代码">
            <a-input
              v-decorator="['agent_code', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="区内企业名称">
            <a-input
              v-decorator="['area_name', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="区内企业代码">
            <a-input
              v-decorator="['area_code', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="电商平台代码">
            <a-input
              v-decorator="['ebp_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="电商平台名称">
            <a-input
              v-decorator="['ebp_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="电商企业代码">
            <a-input
              v-decorator="['ebc_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="电商企业名称">
            <a-input
              v-decorator="['ebc_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="物流企业代码">
            <a-input
              v-decorator="['logistics_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="物流企业名称">
            <a-input
              v-decorator="['logistics_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="订单号">
            <a-input
              v-decorator="['order_no', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="物流单号">
            <a-input
              v-decorator="['logistics_no', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="企业唯一编号">
            <a-input
              v-decorator="['cop_no', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="担保企业编号">
            <a-input
              v-decorator="['assure_code', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="贸易方式">
            <a-input
              v-decorator="['trade_mode', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="运输方式">
            <a-input
              v-decorator="['traf_mode', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="监管库编号">
            <a-input
              v-decorator="['loct_no', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="商务部许可证">
            <a-input
              v-decorator="['license_no', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="起运国">
            <a-select
              v-decorator="[
                'country',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="运费">
            <a-input-number v-decorator="['freight', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="保价费">
            <a-input-number v-decorator="['insured_fee', {rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="毛重">
            <a-input-number v-decorator="['gross_weight', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="净重">
            <a-input-number
              v-decorator="['net_weight', {rules: [{required: false, message: '请输入选择'}]}] ">
            </a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider orientation="left">
        订单商品
      </a-divider>
      <a-table
        :columns="columns"
        rowKey="id"
        :scroll="{ x: 1000 }"
        :data-source="order_goods_list"
        bordered>
        <template
          v-for="col in ['gname', 'gmodel', 'gcode', 'item_no', 'qty', 'qty1','price', 'total_price']"
          :slot="col"
          slot-scope="text, record_item"
        >
          <div :key="col">
            <a-input
              v-if="record_item.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record_item.id, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template slot="unit" slot-scope="text, record_item">
          <a-select
            v-if="record_item.editable"
            style="margin: -5px 0"
            placeholder="选择"
            show-search
            option-filter-prop="children"
            :default-value="units[text]"
            @change="e => handleChange(e,record_item.id ,'unit')"
          >
            <a-select-option :key="key" :value="key" v-for="(val, key) in units">{{ val }}</a-select-option>
          </a-select>
          <template v-else>
            {{ units[text] }}
          </template>
        </template>
        <template slot="unit1" slot-scope="text, record_item">
          <a-select
            v-if="record_item.editable"
            style="margin: -5px 0"
            placeholder="选择"
            show-search
            option-filter-prop="children"
            :default-value="units[text]"
            @change="e => handleChange(e,record_item.id ,'unit1')"
          >
            <a-select-option :key="key" :value="key" v-for="(val, key) in units">{{ val }}</a-select-option>
          </a-select>
          <template v-else>
            {{ units[text] }}
          </template>
        </template>
        <template slot="country" slot-scope="text, record_item">
          <a-select
            v-if="record_item.editable"
            style="margin: -5px 0"
            placeholder="选择"
            show-search
            option-filter-prop="children"
            :default-value="country[text]"
            @change="e => handleChange(e,record_item.id ,'country')"
          >
            <a-select-option :key="key" :value="key" v-for="(val, key) in country">{{ val }}</a-select-option>
          </a-select>
          <template v-else>
            {{ country[text] }}
          </template>
        </template>
        <template slot="operation" slot-scope="text, record_item">
          <div class="editable-row-operations">
            <span v-if="record_item.editable">
              <a @click="() => save(record_item.id)">保存</a>
              <a-divider type="vertical" />
              <a-popconfirm title="确定取消吗?" @confirm="() => cancel(record_item.id)">
                <a>取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a :disabled="editingKey !== ''" @click="() => edit(record_item.id)">编辑</a>
            </span>
          </div>
        </template>
      </a-table>
      <a-row>
        <a-col :span="14" style="text-align: center">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
        <a-col :span="10" v-if="true">
          <a-button @click="handleGoBack">返回</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { customs_inventory_detail, customs_inventory_patch } from '@/api/customs_order'

export default {
  name: 'Edit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      formLayout: 'horizontal',
      id: 0,
      order_goods_list: [],
      cacheData: [],
      units: this.$Dictionaries.unit,
      country: this.$Dictionaries.country,
      editingKey: '',
      columns: [
        {
          title: '名称',
          dataIndex: 'gname',
          width: 150,
          fixed: 'left',
          scopedSlots: { customRender: 'gname' }
        },
        {
          title: '规格型号',
          dataIndex: 'gmodel',
          width: 150,
          scopedSlots: { customRender: 'gmodel' }
        },
        {
          title: '编号',
          dataIndex: 'gcode',
          width: 150,
          scopedSlots: { customRender: 'gcode' }
        },
        {
          title: '企业商品货号',
          dataIndex: 'item_no',
          width: 200,
          scopedSlots: { customRender: 'item_no' }
        },
        {
          title: '单位',
          dataIndex: 'unit',
          width: 150,
          scopedSlots: { customRender: 'unit' }
        },
        {
          title: '法定单位',
          dataIndex: 'unit1',
          width: 150,
          scopedSlots: { customRender: 'unit1' }
        },
        {
          title: '数量',
          dataIndex: 'qty',
          width: 100,
          scopedSlots: { customRender: 'qty' }
        },
        {
          title: '法定数量',
          dataIndex: 'qty1',
          width: 100,
          scopedSlots: { customRender: 'qty1' }
        },
        {
          title: '价格',
          dataIndex: 'price',
          width: 150,
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '总价',
          dataIndex: 'total_price',
          width: 150,
          scopedSlots: { customRender: 'total_price' }
        },
        {
          title: '国家',
          dataIndex: 'country',
          width: 150,
          scopedSlots: { customRender: 'country' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 150,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    console.log('====>')

    customs_inventory_detail(this.record.id).then(({ data }) => {
      console.log(data)
      this.$nextTick(() => {
        if (data !== undefined) {
          this.loadEditInfo(data)
          if (data.inventory_list) {
            this.order_goods_list = data.inventory_list.map(item => {
              item.editable = false
              item.create_time = undefined
              item.last_update = undefined
              item.is_deleted = undefined
              item.e_order_id = undefined
              item.currency = undefined
              return item
            })

            this.cacheData = this.order_goods_list.map(item => ({ ...item }))
          }
        }
      })
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }

        this.order_goods_list = this.order_goods_list.map(item => {
          item.editable = undefined
          return item
        })
        const requestData = { ...values, inventory_list: this.order_goods_list }
        customs_inventory_patch(requestData, this.record.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
          })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      console.log('=====》》》', data)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['ems_no', 'ie_flag', 'customs_code', 'port_code', 'buyer_id_number', 'buyer_name', 'buyer_telephone',
          'consignee_address', 'agent_code', 'agent_name',
          'area_code', 'area_name', 'ebp_code', 'ebp_name', 'ebc_code', 'ebc_name', 'logistics_code', 'logistics_name', 'order_no',
          'logistics_no', 'cop_no', 'assure_code', 'trade_mode', 'traf_mode', 'loct_no', 'license_no', 'country', 'freight', 'insured_fee', 'gross_weight', 'net_weight'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    edit (key) {
      const newData = [...this.order_goods_list]
      const target = newData.find(item => key === item.id)
      this.editingKey = key
      if (target) {
        target.editable = true
        this.order_goods_list = newData
      }
    },
    save (key) {
      const newData = [...this.order_goods_list]
      const newCacheData = [...this.cacheData]
      const target = newData.find(item => key === item.id)
      const targetCache = newCacheData.find(item => key === item.id)
      if (target && targetCache) {
        delete target.editable
        this.order_goods_list = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel (key) {
      const newData = [...this.order_goods_list]
      const target = newData.find(item => key === item.id)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.id))
        delete target.editable
        this.order_goods_list = newData
      }
    },
    handleChange (value, key, column) {
      const newData = [...this.order_goods_list]
      const target = newData.find(item => key === item.id)
      if (target) {
        target[column] = value
        this.order_goods_list = newData
      }
    }
  }
}
</script>
