<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.order_no" placeholder="请输入订单号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="唯一标识">
              <a-input v-model="queryParam.guid" placeholder="请输入唯一标识" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="订购人">
              <a-input v-model="queryParam.buyer_name" placeholder="请输入订购人" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="订购人电话">
              <a-input v-model="queryParam.buyer_telephone" placeholder="请输入电话" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="订购人证件号码">
              <a-input v-model="queryParam.buyer_id_number" placeholder="请输入证件号码" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="推送状态">
              <a-select
                placeholder="请选择"
                @search="handleTagSearch"
                @change="handleTagSearchChange"
                :default-active-first-option="true"
                allowClear
              >
                <a-select-option v-for="(val,key) in dataSource_tag" :key="key" :tt="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="是否能推送">
              <a-select
                placeholder="请选择"
                @change="handleCanPushChange"
                allowClear
              >
                <a-select-option v-for="(val,key) in can_pushed" :key="key" :tt="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="是否自动推送">
              <a-select
                placeholder="请选择"
                @change="handleIsAutoPushChange"
                allowClear
              >
                <a-select-option v-for="(val,key) in is_auto_push" :key="key" :tt="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="line-height: 1; height: 40px">
            <a-form-item label="状态">
              <a-select
                @change="handleReturnStatusSearchChange"
                placeholder="请选择"
                allowClear
              >
                <a-select-option v-for="(val,key) in order_return_status" :key="key" :tt="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :scroll="{x:1300}"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 100px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handlePush(record)" v-if="record.can_push">{{ record.is_pushed?'重新推送':'推送' }}</a>
          <a-divider type="vertical" v-if="record.can_push"/>
          <a @click="handleWatch(record)">订单详情</a>
          <a-divider type="vertical"/>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleStatus(record)">状态记录</a>
          <a-divider type="vertical"v-if="!record.is_pushed"/>

          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a v-if="!record.is_pushed">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <customs-order-form
      ref="CustomsOrderForm"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'

import {
  customs_inventory_delete,
  customs_inventory_list,
  customs_inventory_push
} from '@/api/customs_order'
import CustomsOrderForm from '@/views/b-customs_order/modules/CustomsOrderForm'

export default {
  name: 'TableList',
  components: {
    CustomsOrderForm,
    STable
  },
  data () {
    return {
      tag_value: '',
      dataSource_tag: [],
      order_return_status: [],
      can_pushed: [],
      is_auto_push: [],
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          fixed: 'left',
          width: 50
        },
        {
          title: '订单号',
          dataIndex: 'order_no',
          fixed: 'left',
          width: 200
        },
        {
          title: '电商平台名称',
          dataIndex: 'ebp_name',
          width: 250
        },
        {
          title: '电商平台代码',
          dataIndex: 'ebp_code',
          width: 150
        },
        {
          title: '物流企业名称',
          dataIndex: 'logistics_name',
          width: 300,
          ellipsis: true
        },
        {
          title: '物流企业代码',
          dataIndex: 'logistics_code',
          width: 200
        },
        {
          title: '物流运单编号',
          dataIndex: 'logistics_no',
          width: 200
        },
        {
          title: '企业内部编号',
          dataIndex: 'cop_no',
          width: 200
        },
        {
          title: '担保企业编号',
          dataIndex: 'assure_code',
          width: 200
        },
        {
          title: '账册编号',
          dataIndex: 'ems_no',
          width: 200
        },
        {
          title: '订购⼈',
          dataIndex: 'buyer_name',
          width: 150
        },
        {
          title: '订购⼈电话',
          dataIndex: 'buyer_telephone',
          width: 150
        },
        {
          title: '订购⼈证件号码',
          dataIndex: 'buyer_id_number',
          width: 180
        },
        {
          title: '电商企业名称',
          dataIndex: 'ebc_name',
          width: 300,
          ellipsis: true
        },
        {
          title: '收货地址',
          dataIndex: 'consignee_address',
          width: 250
        },
        {
          title: '区内企业名称',
          dataIndex: 'area_name',
          width: 300,
          ellipsis: true
        },
        {
          title: '区内企业代码',
          dataIndex: 'area_code',
          width: 150
        },
        {
          title: '运输方式',
          dataIndex: 'traf_mode',
          width: 150
        },
        {
          title: '监管场所代码',
          dataIndex: 'loct_no',
          customRender: (text) => text || '无',
          width: 180
        },
        {
          title: '许可证件号',
          dataIndex: 'license_no',
          customRender: (text) => text || '无',
          width: 180
        },
        {
          title: '运费',
          dataIndex: 'freight',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '保价费',
          dataIndex: 'insured_fee',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '毛重',
          dataIndex: 'gross_weight',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '净重',
          dataIndex: 'net_weight',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '唯一标识',
          dataIndex: 'guid',
          width: 400
        },
        {
          title: '申报企业名称',
          dataIndex: 'agent_name',
          width: 300,
          ellipsis: true
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time',
          width: 200
        },
        {
          width: 100,
          title: '推送状态',
          fixed: 'right',
          dataIndex: 'is_pushed',
          customRender: (text) => text ? '已推送' : '未推送'
        },
        {
          width: 100,
          title: '状态',
          fixed: 'right',
          dataIndex: 'return_status',
          ellipsis: true,
          customRender: (text) => this.order_return_status[text] ? this.order_return_status[text] : text
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 300,
          scopedSlots: { customRender: 'action' },
          fixed: 'right'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return customs_inventory_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log('====================', data, '我是数据列表@')
            return data
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries)
    this.dataSource_tag = this.$Dictionaries.is_pushed
    this.is_auto_push = this.$Dictionaries.is_auto_pushed
    this.can_pushed = this.$Dictionaries.can_pushed
    this.order_return_status = this.$Dictionaries.order_return_status
  },
  methods: {
    handleTagSearch (value) {
      console.log(value, '98989')
      this.dataSource_tag = value
    },
    handleTagSearchChange (value) {
      console.log(value)
      if (undefined === value) {
        this.queryParam.is_pushed = undefined
      } else {
        this.queryParam.is_pushed = value === '0'
      }
      // this.tag_value = this.dataSource_tag[value].name
    },
    handleCanPushChange (value) {
      if (undefined === value) {
        this.queryParam.can_push = undefined
      } else {
        this.queryParam.can_push = value === '1'
      }
    },
    handleIsAutoPushChange (value) {
      if (undefined === value) {
        this.queryParam.is_auto_push = undefined
      } else {
        this.queryParam.is_auto_push = value === '1'
      }
    },
    handleReturnStatusSearchChange (value) {
      if (undefined === value) {
        this.queryParam.return_status = undefined
      } else {
        this.queryParam.return_status = value
      }
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handlePush (record) {
      customs_inventory_push(record, record.id).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.$message.success('推送成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleWatch (record) {
      console.log(record.id)
      this.$router.push('/e/customs_order/' + record.id + '/list')
    },
    handleStatus (record) {
      // this.$emit('onRead', record)
      this.$router.push('/e/customs_order/' + record.order_no + '/records')
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      customs_inventory_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          // exchange_rate_create(values).then(res => {
          //     this.visible = false
          //     this.confirmLoading = false
          //     // 重置表单数据
          //     form.resetFields()
          //     // 刷新表格
          //     this.$refs.table.refresh()
          //   })
          //   .catch((err) => {
          //     console.log(err)
          //     this.confirmLoading = false
          //   })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
